// import React from 'react';
// import './AboutV2.css';

// function About() {
//     return(
//         <div class="main-about">
//             <h1>About Me</h1>
//             <div class="about-column">
//                 <p>
//                     Hello! I'm Jatin, and I've always been captivated by the potential of technology to tackle complex problems and create meaningful impact. My academic journey began at the University of Texas at Austin, where I immersed myself in Machine Learning and AI, driven by a curiosity about how algorithms can learn from and adapt to the world around us.
//                 </p>
//                 <p>
//                     In addition to my major, I pursued minors in Business Entrepreneurship and Design Strategies, which provided me with a unique combination of technical expertise and creative problem-solving skills. My education extended beyond just understanding technology—it was about learning how to innovate responsibly and ethically in a rapidly evolving field.
//                 </p>
//             </div>
//             <div class="about-column">
//                 <p>
//                     My passion for AI deepened through hands-on projects and internships where I applied my knowledge to real-world challenges. Whether enhancing software quality with advanced machine learning models at Aristocrat Gaming or implementing cloud security protocols at Kellogg Brown and Root, each experience has been a stepping stone towards a more profound understanding of AI's potential and its broader implications.
//                 </p>
//                 <p>
//                     I'm now excited to continue my educational journey at Cornell Tech, where I aim to not only refine my technical skills but also explore how emerging technologies can be leveraged to drive positive change. On this personal website, I share insights from my journey, showcase the projects that have shaped my path, and offer my thoughts on the future of technology.
//                 </p>
//             </div>
//         </div>
//     );
// }

// export default About;


import React from 'react';
import './AboutV2.css';

function About() {
    return(
        <div className="main-about">
            <h1>About Me</h1>
            <div className="about-column">
                <p>
                    Hello! I'm Jatin, and I've always been captivated by the potential of technology to tackle complex problems and create meaningful impact. My academic journey began at the University of Texas at Austin, where I immersed myself in Machine Learning and AI, driven by a curiosity about how algorithms can learn from and adapt to the world around us.
                </p>
                <p>
                    In addition to my major, I pursued minors in Business Entrepreneurship and Design Strategies, which provided me with a unique combination of technical expertise and creative problem-solving skills. My education extended beyond just understanding technology—it was about learning how to innovate responsibly and ethically in a rapidly evolving field.
                </p>
            </div>
            <div className="about-column">
                <p>
                    My passion for AI deepened through hands-on projects and internships where I applied my knowledge to real-world challenges. Whether enhancing software quality with advanced machine learning models at Aristocrat Gaming or implementing cloud security protocols at Kellogg Brown and Root, each experience has been a stepping stone towards a more profound understanding of AI's potential and its broader implications.
                </p>
                <p>
                    I'm now excited to continue my educational journey at Cornell Tech, where I aim to not only refine my technical skills but also explore how emerging technologies can be leveraged to drive positive change. On this personal website, I share insights from my journey, showcase the projects that have shaped my path, and offer my thoughts on the future of technology.
                </p>
            </div>
            <div className="resume-button-container">
                <a href="/resume.pdf" target="_blank" rel="noopener noreferrer" className="resume-button">
                    View My Resume
                </a>
            </div>
        </div>
    );
}

export default About;
