import React from 'react';
import './MediumCard.css'; // Ensure CSS is correctly imported

const MediumCard = ({ article }) => {
    const handleClick = () => {
        // window.location.href = article.link; // Or use a router if you are using one
        window.open(article.link, '_blank', 'noopener,noreferrer');
    };

    console.log("Article", article)

    return (
        <div className="card-view" onClick={handleClick}>
            <div className="card-body">
                <h2 className="card-title">{article.title}</h2>
                <p className="card-date">
                    {new Date(article.pubDate).toLocaleDateString()}
                </p>
                <p className="card-author">{article.author}</p>
            </div>
        </div>
    );
};

export default MediumCard;
