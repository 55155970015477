// import React, { useEffect, useState } from 'react';
// import './Projects.css';

// function Projects() {
//     const [projects, setProjects] = useState([]);

//     useEffect(() => {
//         fetch('/projects.json')
//             .then(response => response.json())
//             .then(data => setProjects(data));
//     }, []);

//     return (
//         <div className="projects-section">
//             <h1>Projects</h1>
//             {projects.map((project, index) => (
//                 <div 
//                     className={`project-card-section ${index % 2 === 0 ? 'left' : 'right'}`} 
//                     key={index}
//                 >
//                     {index % 2 === 0 ? (
//                         <>
//                             <div className="project-card-title">
//                                 <h1>{project.title}</h1>
//                             </div>
//                             <div className="project-card-description">
//                                 <div className="project-card-subtitle">
//                                     <h2>{project.semester}</h2>
//                                     <h3>{project.subtitle}</h3>
//                                 </div>
//                                 <div className="project-card-info">
//                                     <p>{project.description}</p>
//                                     {project.link && (
//                                         <a href={project.link} target="_blank" rel="noreferrer">Visit Here</a>
//                                     )}
//                                 </div>
//                             </div>
//                         </>
//                     ) : (
//                         <>
//                             <div className="project-card-description">
//                                 <div className="project-card-subtitle">
//                                     <h2>{project.semester}</h2>
//                                     <h3>{project.subtitle}</h3>
//                                 </div>
//                                 <div className="project-card-info">
//                                     <p>{project.description}</p>
//                                     {project.link && (
//                                         <a href={project.link} target="_blank" rel="noreferrer">Visit Here</a>
//                                     )}
//                                 </div>
//                             </div>
//                             <div className="project-card-title">
//                                 <h1>{project.title}</h1>
//                             </div>
//                         </>
//                     )}
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default Projects;



import React, { useEffect, useState, useRef } from 'react';
import './Projects.css';

function Projects() {
    const [projects, setProjects] = useState([]);
    const [expandedProject, setExpandedProject] = useState(null); // Track the expanded project
    const [heights, setHeights] = useState({}); // Track the height of each full article
    const contentRefs = useRef([]); // Store refs to the fullArticle elements

    useEffect(() => {
        fetch('/projects.json')
            .then(response => response.json())
            .then(data => setProjects(data));
    }, []);

    const toggleExpand = (index) => {
        setExpandedProject(expandedProject === index ? null : index); // Toggle the expanded project
    };

    useEffect(() => {
        // Set the height for each content when the component mounts or updates
        const newHeights = projects.reduce((acc, _, index) => {
            const ref = contentRefs.current[index];
            if (ref) {
                acc[index] = ref.scrollHeight;
            }
            return acc;
        }, {});
        setHeights(newHeights);
    }, [projects, expandedProject]);

    return (
        <div className="projects-section">
            <h1>Projects</h1>
            {projects.map((project, index) => (
                <div 
                    className={`project-card-section ${index % 2 === 0 ? 'left' : 'right'}`} 
                    key={index}
                >
                    {index % 2 === 0 ? (
                        <>
                            <div className="project-card-title">
                                <h1>{project.title}</h1>
                            </div>
                            <div className="project-card-description">
                                <div className="project-card-subtitle">
                                    <h2>{project.semester}</h2>
                                    <h3>{project.subtitle}</h3>
                                </div>
                                <div 
                                    className={`project-card-info ${expandedProject === index ? 'expanded' : ''}`}
                                >
                                    <p>{project.description}</p> {/* Always visible */}
                                    {project.fullArticle && (
                                        <>
                                            <button 
                                                onClick={() => toggleExpand(index)} 
                                                className="read-more-button"
                                            >
                                                {expandedProject === index ? 'Show Less' : 'Read More'}
                                            </button>
                                            <div
                                                className="expanded-content"
                                                ref={(el) => (contentRefs.current[index] = el)} // Attach ref to the fullArticle div
                                                style={{
                                                    maxHeight: expandedProject === index ? `${heights[index]}px` : '0px',
                                                    overflow: 'hidden',
                                                    transition: 'max-height 0.3s ease-out'
                                                }}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: project.fullArticle }} />
                                            </div>
                                        </>
                                    )}
                                    {project.link && (
                                        <a href={project.link} target="_blank" rel="noreferrer">Visit Here</a>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="project-card-description">
                                <div className="project-card-subtitle">
                                    <h2>{project.semester}</h2>
                                    <h3>{project.subtitle}</h3>
                                </div>
                                <div 
                                    className={`project-card-info ${expandedProject === index ? 'expanded' : ''}`}
                                >
                                    <p>{project.description}</p> {/* Always visible */}
                                    {project.fullArticle && (
                                        <>
                                            <button 
                                                onClick={() => toggleExpand(index)} 
                                                className="read-more-button"
                                            >
                                                {expandedProject === index ? 'Show Less' : 'Read More'}
                                            </button>
                                            <div
                                                className="expanded-content"
                                                ref={(el) => (contentRefs.current[index] = el)} // Attach ref to the fullArticle div
                                                style={{
                                                    maxHeight: expandedProject === index ? `${heights[index]}px` : '0px',
                                                    overflow: 'hidden',
                                                    transition: 'max-height 0.3s ease-out'
                                                }}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: project.fullArticle }} />
                                            </div>
                                        </>
                                    )}
                                    {project.link && (
                                        <a href={project.link} target="_blank" rel="noreferrer">Visit Here</a>
                                    )}
                                </div>
                            </div>
                            <div className="project-card-title">
                                <h1>{project.title}</h1>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Projects;
