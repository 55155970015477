import React from 'react';
import './ExperienceV2.css';
import aristocratLogo from '../assets/Aristocrat_logo.png';
import kbrLogo from '../assets/KBR_logo.png';
import raiiLogo from '../assets/RAII_logo.png';
import utLogo from '../assets/UT_logo.png';

function Experience() {
    return (
        <div className="experience-section">
            <h1>Experience</h1>
            <div className="timeline-section">
                <div className="timeline-heading">
                    <img src={aristocratLogo} className="company-logo" alt="Aristocrat Logo"/>
                    <h2>Aristocrat Labs</h2>
                </div>
                <div className="timeline-content">
                    <h2> AI Research & Development Intern</h2>
                    <h3>January 2024 – July 2024</h3>
                    <hr /> {/* Horizontal line */}
                    <ul>
                        <li>
                            Spearheaded the development of advanced machine learning models for test case 
                            generation and analysis to optimize unit testing processes and identify patterns 
                            in flaky tests.
                        </li>
                        <li>
                            Led a comprehensive research initiative on defect prediction and root cause analysis 
                            using data analysis techniques to enhance software quality proactively.
                        </li>
                        <li>
                            Integrated Retrieval-Augmented Generation (RAG) techniques to build sophisticated internal 
                            tooling for quality assurance, improving the relevance and accuracy of automated test cases.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="timeline-section">
                <div className="timeline-content">
                    <h2>Technology Rotational Intern</h2>
                    <h3>May 2023 – August 2023</h3>
                    <hr /> {/* Horizontal line */}
                    <ul>
                        <li>
                            Configured Cloud Security Protocols in Azure Active Directory and AWS Identity and Access 
                            Manager for new projects and team members.
                        </li>
                        <li>
                            Implemented Azure AI Search (Azure Cognitive Search) to enhance the company's document 
                            management system by automating document indexing and retrieval.
                        </li>
                        <li>
                            Performed data analysis on over 600,000 tickets to locate trends and improve the efficiency 
                            of the Information Technology department.
                        </li>
                    </ul>
                </div>
                <div className="timeline-heading">
                    <img src={kbrLogo} className="company-logo" alt="KBR Logo"/>
                    <h2>Kellogg Brown and Root</h2>
                </div>
            </div>
            <div className="timeline-section">
                <div className="timeline-heading">
                    <img src={raiiLogo} className="company-logo" alt="RAII Logo" />
                    <h2>Responsible Artificial Intelligence Institute</h2>
                </div>
                <div className="timeline-content">
                    <h2>Software Engineering Intern</h2>
                    <h3>January 2022 – December 2022</h3>
                    <hr /> {/* Horizontal line */}
                    <ul>
                        <li>
                            Assisted in the design and planning of the third version of the RAI Collab, updating the technical 
                            stack to increase web traffic capacity by 100% and save 20 hours per week on maintenance.
                        </li>
                        <li>
                            Expedited the timeline of the RAI Collab Portal by re-engineering the front-end UX/UI development 
                            and creating several tools used in the portal.
                        </li>
                        <li>
                            Developed the AI Regulatory Tracker Web Application using React and Google Firebase, tracking over 
                            170 AI-related regulations globally.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="timeline-section">
                <div className="timeline-content">
                    <h2>Undergraduate Researcher</h2>
                    <h3>August 2021 – December 2021</h3>
                    <hr /> {/* Horizontal line */}
                    <ul>
                        <li>
                            Conducted a detailed study on human-robot interactions, focusing on the perceived safety of subjects 
                            when walking past a Boston Dynamics robot, Spot, in different conditions.
                        </li>
                        <li>
                            Utilized ROS programming and RVIZ to create SLAM maps of test environments, enhancing the accuracy of 
                            robot navigation and subject monitoring during experiments.
                        </li>
                        <li>
                            Implemented Azure Kinect and TF Echo in the programming of Spot to detect and track human positions 
                            in real-time, ensuring safe interactions at speeds up to 5 mph.
                        </li>
                        <li>
                            Analyzed qualitative data from surveys to assess variations in human comfort levels with and without 
                            visible robot constraints, contributing to research on improving robot design for public safety.
                        </li>
                    </ul>
                </div>
                <div className="timeline-heading">
                    <img src={utLogo} className="company-logo" alt="UT Logo"/>
                    <h2>Undergraduate Research: Autonomous Robotics</h2>
                </div>
            </div>
        </div>
    );
}

export default Experience;