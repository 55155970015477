import React from 'react';
import './Blog.css';
import MediumArticles from '../components/MediumArticles'

function Blog() {
    return(
        <div className='blog-section'>
            <h1>Blog Posts</h1>
            <div className="blog-container">
            <MediumArticles/>
            </div>
        </div>
    )
}

export default Blog;