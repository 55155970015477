import React from 'react';
import './Contact.css';

function Contact() {
    return (
        <div className='contact-section'>
            <h1>Contact Information</h1>
            <div className="contact-container">
                <div className="contact-details">
                    <p>Email: contact@jatinkulkarni.com</p>
                    <p>Location: New York City, NY 10044</p>
                    <p>Phone: (832) 623-2238</p>
                    <p>LinkedIn: <a href="https://www.linkedin.com/in/jatin-kulkarni/" target="_blank" rel="noopener noreferrer">Jatin Kulkarni</a></p>
                    <p>GitHub: <a href="https://github.com/jatinkulkarni" target="_blank" rel="noopener noreferrer">jatinkulkarni</a></p>
                </div>
            </div>
        </div>
    );
}

export default Contact;
