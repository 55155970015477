import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import Firebase
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseConfig } from './firebaseConfig.js';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Track a page view when the app starts
logEvent(analytics, 'page_view')



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// Report web vitals
reportWebVitals();

export { analytics };