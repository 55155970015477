import React, { useEffect } from 'react';
import './HomeV2.css';
// import background_nyc from '../assets/NYC-Background.png'
// import github from '../assets/github-svg.svg'
// import linkedin from '../assets/linkedin-svg.svg'
import { logEvent } from 'firebase/analytics';
import { analytics } from '../index.js'


function Home() {
    useEffect(() => {
        logEvent(analytics, 'home_view')
        console.log('home_view')
    }, []);

    return (
        <div className="main">
            {/* <img src={background_nyc} alt="Background of UT" className="full-image"/> */}
            <div class="column">
                <div className="background-darkening"/>
            </div>
            <div class="column">
                <div className="main-text">
                    <h1>
                        Welcome!
                    </h1>
                    <p>
                    I'm Jatin Kulkarni, a Computer Science graduate from the University of Texas at Austin, where I specialized in Machine Learning and Artificial Intelligence. I'm currently advancing my journey at Cornell Tech in New York City, pursuing a Master of Engineering in Computer Science. Here, you can explore my projects, read about my experiences, and discover what fuels my passion for technology.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Home;