import React from 'react';
import './App.css';
import Navbar from './pages/Navbar.js';
// import Home from './pages/Home.js';
import Home from './pages/HomeV2.js';
// import About from './pages/About.js';
import About from './pages/AboutV2.js';
// import Experience from './pages/Experience.js';
import Experience from './pages/ExperienceV2.js';
import Projects from './pages/Projects.js';
import Contact from './pages/Contact.js';
import Blog from './pages/Blog.js'

function App() {
  return (
    <div className='Full-page'>
      <Navbar sections={['home', 'about', 'experience', 'projects', 'blog', 'contact']} />
      <section id="home">
        <Home/>
      </section>
      <section id="about">
        <About/>
      </section>
      <section id="experience">
        <Experience/>
      </section>
      <section id="projects">
        <Projects/>
      </section>
      <section id="blog">
        <Blog/>
      </section>
      <section id="contact">
        <Contact/>
      </section>
    </div>
  );
}

export default App;
