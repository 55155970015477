import React, { useState, useEffect } from 'react';
import MediumCard from './MediumCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

const MediumArticles = () => {
    const [articles, setArticles] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@j10.kulkarni")
            .then(res => res.json())
            .then(data => {
                setArticles(data.items);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);

    if (error) {
        return <Alert variant="danger">Error: {error}</Alert>;
    }

    return (
        <Row>
            {isLoading ? (
                <Col>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            ) : (
                articles.map((article, index) => (
                    <Col key={index} lg={4} md={6} className="mb-4">
                        <MediumCard article={article} />
                    </Col>
                ))
            )}
        </Row>
    );
};

export default MediumArticles;
