import React from 'react';
import './Navbar.css';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../index.js'

function Navbar({ sections }) {
  // Function to handle scrolling to a section
  const scrollToSection = (sectionId) => {
    logEvent(analytics, `scroll_to_${sectionId}`)
    console.log(`Scrolling to section with ID: ${sectionId}`);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Section with ID "${sectionId}" not found.`);
    }
  };

  return (
    <div id="top-bar">
      {sections.map((sectionId, index) => (
        <div key={index} className="top-bar-item" onClick={() => scrollToSection(sectionId)}>
          {sectionId.charAt(0).toUpperCase() + sectionId.slice(1)}
        </div>
      ))}
    </div>
  );
}

export default Navbar;
